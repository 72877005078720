import React, { useEffect, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { useHistory, useParams } from "react-router-dom";
import { handlePutRequest } from "../../services/PutTemplate";
import { handleGetRequest } from "../../services/GetTemplate";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useDispatch } from "react-redux";
import { Router } from "react-router-dom/cjs/react-router-dom";

function CouponUpdate() {
    const [manufacturer, setManufacturers] = useState();
    const history = useHistory();
    const { id } = useParams();
    const [type, setType] = useState("all");
    const [name, setName] = useState("");
    const [couponCode, setCouponCode] = useState("");
    const [productType, setProductType] = useState("all");
    const [brand, setBrand] = useState(null);
    const [category, setCategory] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [noOfUse, setNoOfUse] = useState("single");
    const [discountPercentage, setDiscountPercentage] = useState(null);
    const [discountPrice, setDiscountPrice] = useState(null);
    const [minimumOrderValue, setMinimumOrderValue] = useState(null);
    const [maxDiscountCap, setMaxDiscountCap] = useState(null);
    const [couponDescription, setCouponDescription] = useState("");
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const dispatch = useDispatch();

    const getData = async () => {
        const res = await handleGetRequest(`/coupon/get/${id}`);
        const cat = await handleGetRequest("/category/all");
        const brand = await handleGetRequest("/brand/all");
        setType(res?.data?.type);
        setName(res?.data?.name);
        setCouponCode(res?.data?.couponCode);
        setProductType(res?.data?.productType);
        setBrand(res?.data?.brand);
        setCategory(res?.data?.category);
        setStartDate(res?.data?.startDate ? new Date(res?.data?.startDate) : null);
        setEndDate(res?.data?.endDate ? new Date(res?.data?.endDate) : null);
        setNoOfUse(res?.data?.noOfUse);
        setDiscountPercentage(res?.data?.discountPercentage);
        setDiscountPrice(res?.data?.discountPrice);
        setMinimumOrderValue(res?.data?.minimumOrderValue);
        setMaxDiscountCap(res?.data?.maxDiscountCap);
        setCouponDescription(res?.data?.couponDescription);
        setManufacturers(res?.data);
        setCategories(cat?.data);
        setBrands(brand?.data);
    };

    useEffect(() => {
        getData();
    }, []);

    const typeOptions = [
        { label: "Product", value: "product" },
        { label: "Shipping", value: "shipping" },
        { label: "Both", value: "both" },
    ];

    const productTypeOptions = [
        { label: "Brand", value: "brand" },
        { label: "Category", value: "category" },
    ];

    const noOfUseOptions = [
        { label: "Single", value: "single" },
        { label: "Multiple", value: "multiple" },
    ];

    const breadItems = [{ label: "Home" }, { label: "Coupon", url: "/coupon" }];
    const home = { icon: "pi pi-home", url: "/" };

    const formik = useFormik({
        initialValues: {
            type: "all",
            name: "",
            couponCode: "",
            productType: "all",
            brand: "",
            category: "",
            startDate: null,
            endDate: null,
            noOfUse: "single",
            discountPercentage: 0,
            discountPrice: 0,
            minimumOrderValue: 0,
            maxDiscountCap: 0,
            couponDescription: "",
        },

        onSubmit: async (data) => {
            const dat = {
                type: type,
                name: name,
                couponCode: couponCode,
                productType: productType,
                brand: brand,
                category: category,
                startDate: startDate,
                endDate: endDate,
                noOfUse: noOfUse,
                discountPercentage: discountPercentage,
                discountPrice: discountPrice,
                minimumOrderValue: minimumOrderValue,
                maxDiscountCap: maxDiscountCap,
                couponDescription: couponDescription,
                _id: id,
            };
            const res = await handlePutRequest(dat, "/coupon/update");
            if (res?.success === true) {
                toast.success("Coupon Updated Successfully");
                
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = () => {
        history.push("/");
    };

    return (
        <>
            <div className="customer_header__">
                <div className="left___">
                    <h2>{manufacturer?.title}</h2>
                    <BreadCrumb model={breadItems} home={home} />
                </div>
            </div>

            <div className="customer_details_section_coupon">
                <div className="right_section">
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px", fontWeight: "600" }}>{name}</div>
                    <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                        <div className="form__">
                            <div className="form_left">
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="type" className={classNames({ "p-error": isFormFieldValid("type") }, "Label__Text")}>
                                        Type
                                    </label>
                                    <Dropdown id="type" name="type" value={type} options={typeOptions} onChange={(e) => setType(e.target.value)} className={classNames({ "p-invalid": isFormFieldValid("type") }, "Input__Round")} />
                                    {getFormErrorMessage("type")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="couponCode" className={classNames({ "p-error": isFormFieldValid("couponCode") }, "Label__Text")}>
                                        Coupon Code
                                    </label>
                                    <InputText id="couponCode" name="couponCode" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} className={classNames({ "p-invalid": isFormFieldValid("couponCode") }, "Input__Round")} />
                                    {getFormErrorMessage("couponCode")}
                                </div>

                                {brand && (
                                    <div style={{ marginTop: "10px" }}>
                                        <label htmlFor="brand" className={classNames({ "p-error": isFormFieldValid("brand") }, "Label__Text")}>
                                            Brand
                                        </label>
                                        <select style={{ marginTop: "10px", height: "35px", borderRadius: "6px", border: "1px solid #cecece" }} value={brand} onChange={(e) => setBrand(e.target.value)}>
                                            <option disabled>Select a brand</option>
                                            {brands?.map((item) => (
                                                <option key={item._id} value={item._id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                        {getFormErrorMessage("brand")}
                                    </div>
                                )}

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="startDate" className={classNames({ "p-error": isFormFieldValid("startDate") }, "Label__Text")}>
                                        Start Date
                                    </label>
                                    <Calendar id="startDate" name="startDate" value={startDate} onChange={(e) => setStartDate(e.value)} className={classNames({ "p-invalid": isFormFieldValid("startDate") }, "Input__Round")} showTime dateFormat="yy-mm-dd" hourFormat="24" />
                                    {getFormErrorMessage("startDate")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="noOfUse" className={classNames({ "p-error": isFormFieldValid("noOfUse") }, "Label__Text")}>
                                        No Of Use
                                    </label>
                                    <Dropdown id="noOfUse" name="noOfUse" value={noOfUse} options={noOfUseOptions} onChange={(e) => setNoOfUse(e.target.value)} className={classNames({ "p-invalid": isFormFieldValid("noOfUse") }, "Input__Round")} />
                                    {getFormErrorMessage("noOfUse")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="minimumOrderValue" className={classNames({ "p-error": isFormFieldValid("minimumOrderValue") }, "Label__Text")}>
                                        Minimum Order Value
                                    </label>
                                    <InputText id="minimumOrderValue" name="minimumOrderValue" value={minimumOrderValue} onChange={(e) => setMinimumOrderValue(e.target.value)} className={classNames({ "p-invalid": isFormFieldValid("minimumOrderValue") }, "Input__Round")} />
                                    {getFormErrorMessage("minimumOrderValue")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="couponDescription" className={classNames({ "p-error": isFormFieldValid("couponDescription") }, "Label__Text")}>
                                        Description
                                    </label>
                                    <InputText id="couponDescription" name="couponDescription" value={couponDescription} onChange={(e) => setCouponDescription(e.target.value)} className={classNames({ "p-invalid": isFormFieldValid("couponDescription") }, "Input__Round")} />
                                    {getFormErrorMessage("couponDescription")}
                                </div>
                            </div>

                            <div className="form_right">
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="productType" className={classNames({ "p-error": isFormFieldValid("productType") }, "Label__Text")}>
                                        Product Type
                                    </label>
                                    <Dropdown id="productType" name="productType" value={productType} options={productTypeOptions} onChange={(e) => setProductType(e.target.value)} className={classNames({ "p-invalid": isFormFieldValid("productType") }, "Input__Round")} />
                                    {getFormErrorMessage("productType")}
                                </div>

                                {category && (
                                    <div style={{ marginTop: "10px" }}>
                                        <label htmlFor="category" className={classNames({ "p-error": isFormFieldValid("category") }, "Label__Text")}>
                                            Category
                                        </label>
                                        <select style={{ marginTop: "10px", height: "35px", borderRadius: "6px", border: "1px solid #cecece" }} value={category} onChange={(e) => setCategory(e.target.value)}>
                                            <option disabled>Select a category</option>
                                            {categories?.map((item) => (
                                                <option key={item._id} value={item._id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                        {getFormErrorMessage("category")}
                                    </div>
                                )}

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="name" className={classNames({ "p-error": isFormFieldValid("name") }, "Label__Text")}>
                                        Name
                                    </label>
                                    <InputText id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__Round")} />
                                    {getFormErrorMessage("name")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="endDate" className={classNames({ "p-error": isFormFieldValid("endDate") }, "Label__Text")}>
                                        End Date
                                    </label>
                                    <Calendar id="endDate" name="endDate" value={endDate} onChange={(e) => setEndDate(e.value)} className={classNames({ "p-invalid": isFormFieldValid("endDate") }, "Input__Round")} showTime minDate={startDate} dateFormat="yy-mm-dd" hourFormat="24" />
                                    {getFormErrorMessage("endDate")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="discountPercentage" className={classNames({ "p-error": isFormFieldValid("discountPercentage") }, "Label__Text")}>
                                        Discount Percentage
                                    </label>
                                    <InputText id="discountPercentage" name="discountPercentage" value={discountPercentage} onChange={(e) => setDiscountPercentage(e.target.value)} className={classNames({ "p-invalid": isFormFieldValid("discountPercentage") }, "Input__Round")} />
                                    {getFormErrorMessage("discountPercentage")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="discountPrice" className={classNames({ "p-error": isFormFieldValid("discountPrice") }, "Label__Text")}>
                                        Discount Price
                                    </label>
                                    <InputText id="discountPrice" name="discountPrice" value={discountPrice} onChange={(e) => setDiscountPrice(e.target.value)} className={classNames({ "p-invalid": isFormFieldValid("discountPrice") }, "Input__Round")} />
                                    {getFormErrorMessage("discountPrice")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="maxDiscountCap" className={classNames({ "p-error": isFormFieldValid("maxDiscountCap") }, "Label__Text")}>
                                        Maximum Discount Cap
                                    </label>
                                    <InputText id="maxDiscountCap" name="maxDiscountCap" value={maxDiscountCap} onChange={(e) => setMaxDiscountCap(e.target.value)} className={classNames({ "p-invalid": isFormFieldValid("maxDiscountCap") }, "Input__Round")} />
                                    {getFormErrorMessage("maxDiscountCap")}
                                </div>
                            </div>
                        </div>

                        <div className="Down__Btn">
                            <Button label="Cancel" className="Btn__Transparent" onClick={handleCancel} />
                            <Button label="Update" className="Btn__Dark" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default CouponUpdate;
